import { CategoryId, getCategoryUrl } from "@telia/b2b-product-categories-lib";
import type { Components } from "@teliads/components/dist/types/components";
import { t } from "./locale";
import { currentLanguage } from "@telia/b2b-i18n";
import { logError } from "@telia/b2x-logging";

export const getDetailsPageUrl = (
  scopeId: string,
  productId: string,
  productCategoryId: CategoryId,
  legacyUrl?: boolean
): string => {
  const baseLink = `/foretag/mybusiness/${scopeId}/hantera/produkter-tjanster`;

  if (legacyUrl) {
    return `${baseLink}/mobila-abonnemang/${productId}`;
  } else {
    return `${baseLink}/${getCategoryUrl(productCategoryId)}/ny/${productId}`;
  }
};

export const isDetailsPageUrl = (path: string): boolean =>
  /^\/foretag\/mybusiness\/.+\/hantera\/produkter-tjanster\/.+\/ny\/.+/.test(path);

// maybe get this from b2b-rest-client -> corp-subscription-details or corp-subscription-overview instead?
export type StatusId =
  | "ACTIVE"
  | "CANCELLED"
  | "CLOSED"
  | "CLOSED_IN"
  | "CLOSED_OUT"
  | "CLOSED_DEBT"
  | "CONFIGURED"
  | "INACTIVE"
  | "IN_TRAFFIC"
  | "PLANNED"
  | "PORTING"
  | "NEW"
  | "READY_FOR_TRAFFIC"
  | "RESERVED"
  | "UNKNOWN";

type StatusProperties = {
  variant: Components.TeliaStatusBadge["variant"];
};

export type TranslatedStatus = {
  id: StatusId;
  translatedName: string;
};

const statuses: Record<StatusId, StatusProperties> = {
  ACTIVE: { variant: "positive" },
  CANCELLED: { variant: "caution" },
  CLOSED: { variant: "caution" },
  CLOSED_IN: { variant: "caution" },
  CLOSED_OUT: { variant: "caution" },
  CLOSED_DEBT: { variant: "warning" },
  CONFIGURED: { variant: "information" },
  INACTIVE: { variant: "caution" },
  IN_TRAFFIC: { variant: "positive" },
  PLANNED: { variant: "information" },
  PORTING: { variant: "information" },
  NEW: { variant: "information" },
  READY_FOR_TRAFFIC: { variant: "information" },
  RESERVED: { variant: "information" },
  UNKNOWN: { variant: "neutral" },
};

/**
 * @param {StatusId} status One of the subscriptionStatus values from backend
 * @returns {Components.TeliaStatusBadge["variant"]} A value allowed in the Status badge Voca component
 */
export const getStatusVariant = (statusId: StatusId): Components.TeliaStatusBadge["variant"] =>
  statuses[statusId]?.variant || "neutral";

/**
 * @param {StatusId} statusId One of the subscriptionStatus values from backend
 * @returns {string}
 */
export const getStatusText = (statusId: StatusId): string => {
  try {
    const translatedName = t(`statusName.${statusId}`);
    if (translatedName) {
      return translatedName;
    } else {
      logError(
        "b2b-subscription-common",
        `Received unexpected statusId "${statusId}", showing it to user as "Unknown"`
      );
      return t("statusName.UNKNOWN");
    }
  } catch {
    return t("statusName.UNKNOWN");
  }
};

/**
 * @returns {TranslatedStatus[]} All possible subscription statuses, sorted in the order they
 * should be displayed, i.e. alphabetically, except "Unknown" is always last
 */
export const getTranslatedAndSortedStatuses = (): TranslatedStatus[] =>
  (Object.entries(statuses) as [StatusId, StatusProperties][])
    .map(
      (statusEntry: [StatusId, StatusProperties]): TranslatedStatus => ({
        id: statusEntry[0],
        translatedName: getStatusText(statusEntry[0]),
      })
    )
    .sort((status1: TranslatedStatus, status2: TranslatedStatus): number => {
      // Place "unknown" status LAST, sort all the others alphabetically
      if (status1.id === "UNKNOWN" && status2.id === "UNKNOWN") {
        return 0;
      } else if (status1.id === "UNKNOWN" && status2.id !== "UNKNOWN") {
        return 1;
      } else if (status1.id !== "UNKNOWN" && status2.id === "UNKNOWN") {
        return -1;
      } else {
        return status1.translatedName.localeCompare(status2.translatedName, currentLanguage(), {
          sensitivity: "base",
        });
      }
    });

export const getStatus = (
  statusId: StatusId
): { variant: Components.TeliaStatusBadge["variant"]; accessibleLabel: string; text: string } => {
  const statusVariant = getStatusVariant(statusId);
  return {
    variant: statusVariant,
    accessibleLabel: t(`accessibleStatusLabel.${statusVariant}`),
    text: getStatusText(statusId),
  };
};
